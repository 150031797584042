import React, { useState } from 'react'
import Aux from '../../../hoc/_Aux'
import logoDark from './../../../assets/images/auth/auth-logo-dark.png'
import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { alert_exitoso, alert_warning } from '../../../helpers/Notificacion';
import callApi from '../../../helpers/conectorApi';
import Loading from './Loading';
import { Row, Col, Form, FormGroup, ButtonGroup, Button, FormControl } from 'react-bootstrap';
import { useForm } from '../../hooks/useForm';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Loki from 'react-loki';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DEMO from '../../../store/constant';
import { useEffect } from 'react';
let maxDateAux = moment().add(-180, 'months');
const maxDate = moment(maxDateAux).format('DD/MM/YYYY');
export const Registro = ({ history }) => {
    const [listMonedas, setListMonedas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [values, handleOnChange, , setValues] = useForm({
        nombre1: '',
        apellido1: '',
        fecha_nacimiento: '',
        nombre_grupo: '',
        password: '',
        password_confirmar: '',
        generoId: '',
        monedaId: '',
        email: ''
    });
    const GetCatalogo = async () => {
        let response = await callApi("ctl/cat/moneda");
        if (response) {
            setListMonedas(response);
        }
    }
    const registrar = async () => {
        setLoading(true);
        let response = await callApi('ctl/registro', {
            method: 'POST',
            body: JSON.stringify(values)
        });
        if (response) {
            const MySwal = withReactContent(Swal);
            MySwal.fire({
                title: 'Información?',
                text: response,
                type: 'success',
                showCloseButton: false,
                showCancelButton: false
            }).then(async (willDelete) => {
                history.replace("/auth/login");
            });
        }
        setLoading(false);
    }


    const complexSteps = [
        {
            label: 'Step 1',
            icon: <i className="fa fa-user" />,
            component: <InformacionPersonal values={values} handleOnChange={handleOnChange} setValues={setValues} />,
        },
        {
            label: 'Step 2',
            icon: <i className="fa fa-users" />,
            component: <InformacionGrupoGasto values={values} handleOnChange={handleOnChange} listMonedas={listMonedas} />,
        },
        {
            label: 'Step 3',
            icon: <i className="fa fa-lock" />,
            component: <InformacionUsuario values={values} setValues={setValues} handleOnChange={handleOnChange} />,
        },
    ];


    const _mergeValues = (values) => {
    }

    const _finishWizard = () => {
        registrar();
    }

    useEffect(() => {
        GetCatalogo();
    }, [])



    return (
        <Aux>
            {
                loading === true ?
                    <Loading /> :
                    <ValidationForm onSubmit={() => { }}>
                        <Loki
                            steps={complexSteps}
                            onNext={_mergeValues.bind(this)}
                            onBack={_mergeValues.bind(this)}
                            onFinish={_finishWizard.bind(this)}
                            noActions />
                    </ValidationForm>
            }
        </Aux>
    )
}

const InformacionPersonal = ({ isSubmitting, onBack, onNext, cantBack, values, handleOnChange, setValues }) => {
    const [correo, setCorreo] = useState('');

    useEffect(() => {
        setCorreo(values.email);
    }, []);

    useEffect(() => {
        setValues({ ...values, email: correo })
    }, [correo]);

    const handleSetCorreo = ({ target }) => {
        setCorreo(String(target.value).trim().toLocaleLowerCase())
    }
    return (
        <>
            <Row className="justify-content-md-center">
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="nombre1">Correo Electrónico</Form.Label>
                        <TextInput
                            name="email"
                            id="email"
                            errorMessage="Campo requerido"
                            value={values.email}
                            onChange={handleSetCorreo}
                            autoComplete="off"
                            type="email"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="nombre1">Nombre</Form.Label>
                        <TextInput
                            name="nombre1"
                            id="nombre1"
                            errorMessage="Campo requerido"
                            value={values.nombre1}
                            onChange={handleOnChange}
                            autoComplete="off"
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="apellido1">Apellido</Form.Label>
                        <TextInput
                            name="apellido1"
                            id="apellido1"
                            errorMessage="Campo requerido"
                            value={values.apellido1}
                            onChange={handleOnChange}
                            autoComplete="off"
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="fecha_nacimiento">Fecha de Nacimiento</Form.Label>
                        <TextInput
                            name="fecha_nacimiento"
                            id="fecha_nacimiento"
                            errorMessage="Campo requerido"
                            value={values.fecha_nacimiento}
                            onChange={handleOnChange}
                            autoComplete="off"
                            type="date"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="generoId">Género</Form.Label>
                        <SelectGroup
                            name="generoId"
                            id="generoId"
                            value={values.generoId}
                            errorMessage="Campo requerido"
                            onChange={handleOnChange}>
                            <option value="">--Seleccione--</option>
                            <option value="1">Masculino</option>
                            <option value="2">Femenino</option>
                        </SelectGroup>
                    </Form.Group>
                </Form.Row>
            </Row>
            <ButtonGroup aria-label="Wizard Buttons">
                <Button variant="secondary" onClick={onBack} disabled={isSubmitting || cantBack}>Anterior</Button>
                <Button variant="primary" onClick={onNext} disabled={isSubmitting}>Siguiente</Button>
            </ButtonGroup>
        </>
    )
}

const InformacionGrupoGasto = ({ isSubmitting, onBack, onNext, cantBack, values, handleOnChange, listMonedas }) => {
    return (
        <>
            <Row className="justify-content-md-center">
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="nombre1">Nombre de Grupo</Form.Label>
                        <TextInput
                            name="nombre_grupo"
                            id="nombre_grupo"
                            required
                            errorMessage="Campo requerido"
                            value={values.nombre_grupo}
                            onChange={handleOnChange}
                            autoComplete="off"
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="monedaId">Moneda</Form.Label>
                        <SelectGroup
                            name="monedaId"
                            id="monedaId"
                            value={values.monedaId}
                            required
                            errorMessage="Campo requerido"
                            onChange={handleOnChange}>
                            <option value="">--Seleccione--</option>
                            {
                                listMonedas.map(({ monedaId, descripcion, simbolo }) => {
                                    return (
                                        <option value={monedaId} key={`mondeda${monedaId}}`}>{`${descripcion} (${simbolo})`}</option>
                                    )
                                })
                            }
                        </SelectGroup>
                    </Form.Group>
                </Form.Row>
            </Row>
            <ButtonGroup aria-label="Wizard Buttons">
                <Button variant="secondary" onClick={onBack} disabled={isSubmitting || cantBack}>Anterior</Button>
                <Button variant="primary" onClick={onNext} disabled={isSubmitting}>Siguiente</Button>
            </ButtonGroup>
        </>
    )
}
const InformacionUsuario = ({ isSubmitting, onBack, onNext, cantBack, values,setValues, handleOnChange }) => {
    const [passIgual, setPassIgual] = useState(false);
    const confirmarPassWord = (value) => {
        let iguales = value === values.password;
        setPassIgual(iguales);
        return value && iguales;
    };
    const hanldeOnChangePassword = ({ target: { value } }) => {
        setValues({ ...values, password: value });
    }
    const hanldeOnChangePasswordConfir = ({ target: { value } }) => {
        setValues({ ...values, password_confirmar: value });
    }
    return (
        <>
            <Row className="justify-content-md-center">
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="password">Usuario</Form.Label>
                        <TextInput
                            name="usuario"
                            id="usuario"
                            value={values.email}
                            type="text"
                            disabled
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="password">Contraseña</Form.Label>
                        <TextInput
                            name="password"
                            id="password"
                            type="password"
                            required
                            pattern="(?=.*[A-Z]).{6,}"
                            errorMessage={{ required: "Ingrese la nueva contraseña", pattern: "La contraseña debe de tener al menos 6 caracteres y contener al menos una letra mayúscula" }}
                            value={values.password}
                            onChange={hanldeOnChangePassword}
                            autoComplete="off"
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="12">
                        <Form.Label htmlFor="password_confirmar">Confirmar Contraseña</Form.Label>
                        <TextInput
                            name="password_confirmar"
                            id="password_confirmar"
                            type="password"
                            required
                            validator={confirmarPassWord}
                            errorMessage={{ required: "Por favor confirme la nueva contraseña", validator: "La contraseña no coincide" }}
                            value={values.password_confirmar}
                            onChange={hanldeOnChangePasswordConfir}
                            autoComplete="off"
                        />
                    </Form.Group>
                </Form.Row>
            </Row>
            <ButtonGroup aria-label="Wizard Buttons">
                <Button variant="secondary" onClick={onBack} disabled={isSubmitting || cantBack}>Anterior</Button>
                <Button variant="primary" onClick={onNext} disabled={isSubmitting || !passIgual}>Finalizar</Button>
            </ButtonGroup>
        </>
    )
}
